import React from 'react'

const Footer = () => {
  return <>
    <footer className="footer">
      <div className="content has-text-centered">
        <p>
          ©2021 凸凹えんた～ていんめんとすたじお
        </p>
      </div>
    </footer>
  </>
}

export default Footer
